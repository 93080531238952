import { writable } from 'svelte/store';
import {CookieGroup} from "$lib/config.ts";

export type AvailableTabs = 'consent' | 'details' | 'about'

interface ModalState {
  tab: AvailableTabs
  close: boolean
  allowedGroups: string[]
}

export const modalState = writable<ModalState>({
  tab: 'consent',
  close: false,
  allowedGroups: [],
});

export const showTab = (newTab: AvailableTabs) => {
  modalState.update((state) => {
    return {
      ...state,
      tab: newTab,
    };
  });
}

export const allowGroup = (group: CookieGroup) => {
  const groupID = group.id;

  modalState.update((state) => {
    let groups = state.allowedGroups;
    if (!groups.includes(groupID)) {
      groups.push(groupID);
    }

    return {
      ...state,
      allowedGroups: groups,
    };
  });
}

export const disallowGroup = (group: CookieGroup) => {
  const groupID = group.id;

  modalState.update((state) => {
    return {
      ...state,
      allowedGroups: state.allowedGroups.filter((g) => g !== groupID),
    };
  });
}

export const closeCookieModal = () => {
  modalState.update((state) => {
    return {
      ...state,
      close: true,
    };
  });
}
