<script lang="ts">
  import {toStyles} from "$lib/utils.ts"

  export let styles: string  = ""
</script>

<div class="cc-preferences-wrapper"
     style={toStyles(styles)}
>
  <slot />
</div>

<style>
  .cc-preferences-wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 768px) {
    .cc-preferences-wrapper {
      flex-direction: column;
    }
  }
</style>