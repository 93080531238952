type ClassParam = string | undefined | null | false

export const addClasses = (...classes: ClassParam[]): string => {
  let res = ''
  for (let i = 0; i < classes.length; i++) {
    res = _addClass(res, classes[i] ?? '') as string
  }

  return res
}

const _addClass = (classes: string, classToAdd: ClassParam): string => {
  if (!classToAdd) {
    return classes
  }

  if (!classes) {
    return classToAdd as string
  } else {
    classes = classes + ' ' + classToAdd
  }

  return classes
}

export function toStyles(obj: Partial<CSSStyleDeclaration> | string): string {
  let data: Partial<CSSStyleDeclaration> = {}
  if (typeof obj === 'string') {
    data = getStylesFromConfig(obj)
  } else {
    data = obj
  }

  let res = ''
  for (const key in data) {
    res += toStyleLine(key, data[key])
  }

  return res
}

export function toStyleLine(key: string, val: string | null | undefined | boolean | number): string | undefined {
  if (!key || val === undefined || val === null || val === "") {
    return ''
  }

  const cssKey = key.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)

  return `${cssKey}: ${val};`
}

export function getStylesFromConfig(styles: string): Partial<CSSStyleDeclaration> {
  const res: Record<string, string> = {}
  if (!styles || typeof styles !== 'string') {
    return res
  }

  const lines = styles.split(';').map((line) => line.trim())
  for (let i = 0; i < lines.length; i++) {
    const [key, val] = lines[i].split(':').map((v) => v.trim())

    const objKey = key.replace(/-([a-z])/g, (m) => m[1].toUpperCase())

    if (!objKey) {
      continue
    }

    res[objKey] = val
  }

  return res
}