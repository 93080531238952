<script lang="ts">
  import {createEventDispatcher} from 'svelte';

  export let enabled: boolean = false;
  export let readOnly: boolean = false;
  export let color: string = 'gray';

  const dispatch = createEventDispatcher();

  function handleChange() {
    if (readOnly) {
      return;
    }
    enabled = !enabled;
    dispatch('change', { enabled });
  }
</script>

<button
    class="switch" on:click={handleChange} style="--color: {color};"
>
  <input type="checkbox" checked={enabled} disabled={readOnly} />
  <span class="slider"></span>
</button>

<style>
  .switch {
    width: 60px;
    height: 30px;
    display: inline-block;
    position: relative;
    border-radius: 15px;
    cursor: pointer;
    border: 0;
    transition: background-color 0.2s;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 15px;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: var(--color);
  }
  input:checked + .slider:before {
    transform: translateX(30px);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--color);
  }
  input:disabled + .slider {
    opacity: 0.6;
    cursor: not-allowed;
  }
</style>
