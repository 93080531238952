import Modal from './Modal.svelte'
import './app.css'
import {
  CookieContentClarity,
  ConsentIsConfigured,
  CookieContentFbPixel,
  CookieContentGtag,
  getStoredConsent,
  Grants
} from "./lib/cookie-data.ts"
import {InitCookieModal, setModalConfig} from "src/lib/config.ts"
import Cookies from "js-cookie"
import {handleConsentChoice} from "$lib/traking-libs.ts";
import {allowGroup} from "$lib/store.ts"

const dynamicClasses = 'cctw-grid-cols-1 cctw-grid-cols-2 cctw-grid-cols-3 cctw-md:grid-cols-2 cctw-md:grid-cols-3 cctw-md:grid-cols-4'

function InitCookieConsent(config: InitCookieModal) {
  setModalConfig(config)
  if (config.debug) {
    console.log("Config Data: ", config)
  }

  const rootElId = config?.rootId
  if (!rootElId) {
    console.log('no rootId')
    return
  }

  const storedData = getStoredConsent()
  if (storedData !== undefined) {
    handleConsentChoice(storedData)
    return
  }

  for (const [_, group] of Object.entries(config.modal.cookieGroups)) {
    allowGroup(group)
  }

  new Modal({
    target: document.getElementById(rootElId) ?? document.body,
    props: {
      config: config,
    },
  })
}

function IsClarityAllowed() {
  return Cookies.get(CookieContentClarity) === 'true'
}

function GetAllowedGrants(): Grants[] {
  const grants = Cookies.get(CookieContentGtag)
  if (!grants) {
    return []
  }

  return grants.split(',') as Grants[]
}

function IsFacebookAllowed(): boolean {
  return Cookies.get(CookieContentFbPixel) === 'true'
}

export default {
  InitCookieConsent,
  GetAllowedGrants,
  IsFacebookAllowed,
  IsClarityAllowed,
  ConsentIsConfigured
}
