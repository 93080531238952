<script lang="ts">
  import {onDestroy, onMount} from 'svelte'
  import MainContent from "./components/MainContent.svelte"
  import {addClasses} from "$lib/utils.ts"
  import type {InitCookieModal} from "src/lib/config.ts"
  import {allowAllCookies, saveConsentChoice} from "$lib/cookie-data.ts";
  import ButtonWrapper from "src/components/ButtonWrapper.svelte";
  import Button from "src/components/Button.svelte";
  import GroupsDetails from "src/components/CookieGroup/GroupsDetails.svelte";
  import type {AvailableTabs} from "$lib/store.ts";
  import {modalState, showTab} from "$lib/store.ts";

  const toggleBodyScroll = (shouldDisable: boolean) => {
    if (shouldDisable) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }

  export let config: InitCookieModal

  function noScroll(node: HTMLElement) {
    onMount(() => {
      toggleBodyScroll(true)
    })

    onDestroy(() => {
      toggleBodyScroll(false)
    })

    return {
      destroy() {
        toggleBodyScroll(false)
      },
    }
  }

  const modalConfig = config.modal ?? {}

  let tabCount = 2
  if (!!modalConfig.about) {
    tabCount = 3
  }

  const tabs: {
    name: AvailableTabs
    text: string
  }[] = [
    {
      name: 'consent',
      text: 'Съгласие',
    },
    {
      name: 'details',
      text: 'Детайли',
    },
  ]

  if (modalConfig.about) {
    tabs.push({
      name: 'about',
      text: 'Информация',
    })
  }
</script>

{#if !$modalState.close}
  <div use:noScroll class='cc-overlay'>
    <div class="cc-modal cctw-bg-ccModalBg cctw-text-ccTextColor">
      <div class="cctw-grid cctw-grid-cols-{tabCount} cc-tabs">
        {#each tabs as tab}
          <button class={addClasses(
            "cc-tab cctw-border-l-0 cctw-border-r-0 cctw-border-t-0 cctw-border-b-2 cctw-text-ccTextColor",
            $modalState.tab === tab.name ?
            'cctw-border-ccPrimary cctw-bg-ccModalBg-hover' :
            'cctw-border-ccBorder cctw-bg-transparent'
          )}
                  on:click={() => showTab(tab.name)}
          >
            {tab.text}
          </button>
        {/each}
      </div>

      <div class="cc-content">
        {#if $modalState.tab === 'details'}
          {#each Object.entries(modalConfig.cookieGroups) as [_, group]}
            <GroupsDetails {group}/>
          {/each}
        {:else}
          <MainContent modalConfig={modalConfig}/>
        {/if}
      </div>
      <ButtonWrapper>
        {#if $modalState.tab === 'details'}
          <Button
            variant="confirm"
            buttonData={{
              text: "Приемам избраните",
            }}
            on:click={saveConsentChoice}
          />
        {:else}
          <Button
            variant="confirm"
            buttonData={{
              text: "Предпочитания",
            }}
            on:click={() => showTab('details')}
          />
        {/if}
        <Button
          variant="accept"
          on:click={allowAllCookies}
          buttonData={{
            text: "Приемам"
          }}
        />
      </ButtonWrapper>
    </div>
  </div>
{/if}

<style>
    .cc-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 9999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .cc-modal {
        width: 100%;
        padding: 0 20px 20px;
        max-width: 800px;
        border-radius: 10px;
        font-family: Roboto, sans-serif;
    }

    .cc-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 70vh;
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        .cc-content {
            max-height: 60vh;
        }
    }

    .cc-tabs {
        margin-bottom: 20px;
    }

    .cc-tab {
        border-style: solid;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
    }
</style>
