import Cookies from "js-cookie"
import {closeCookieModal, modalState} from "$lib/store.ts";
import {CookieGroup, getModalConfig, isDebugMode} from "$lib/config.ts";
import {get} from "svelte/store";
import {handleConsentChoice} from "$lib/traking-libs.ts";

export const CookieContentClarity = '_cookie_clarity'
export const CookieContentGtag = '_cookie_consent_gtag'
export const CookieContentFbPixel = '_cookie_consent_fb'

export type CookieVendorKey = 'google' | 'facebook' | 'clarity' | 'store'
export type Grants =
  | 'ad_storage'
  | 'ad_user_data'
  | 'ad_personalization'
  | 'analytics_storage'

export interface StoredConsentData {
  facebook: boolean
  google: boolean
  gtagGrants: Record<Grants, "granted">
  clarity: boolean
}

const ExpirationDays = 365

export const GoogleVendor = 'google'
export const FacebookVendor = 'facebook'
export const ClarityVendor = 'clarity'
export const StoreVendor = 'store'

export function isGroupNecessary(group?: CookieGroup): boolean {
  return (group && group.vendors?.keys?.includes(StoreVendor)) || false
}

export function ConsentIsConfigured(): boolean {
  if (
    Cookies.get(CookieContentClarity) === undefined ||
    Cookies.get(CookieContentGtag) === undefined ||
    Cookies.get(CookieContentFbPixel) === undefined
  ) {
    return false
  }

  return true
}

export function getStoredConsent(): StoredConsentData | undefined {
  if (!ConsentIsConfigured()) {
    return undefined
  }

  const initData = {
    facebook: false,
    google: false,
    gtagGrants: {} as Record<string, "granted">,
    clarity: false,
  }

  const clarity = Cookies.get(CookieContentClarity)
  if (clarity === 'true') {
    initData.clarity = true
  }

  const gtagGrants = Cookies.get(CookieContentGtag)
  if (gtagGrants) {
    const grants = gtagGrants.split(',')
    if (grants.length > 0) {
      initData.google = true
      for (const grant of grants) {
        initData.gtagGrants[grant] = 'granted'
      }
    }
  }

  const fbPixel = Cookies.get(CookieContentFbPixel)
  if (fbPixel === 'true') {
    initData.facebook = true
  }

  return initData
}

function saveConsentToCookie(data: StoredConsentData) {
  if (isDebugMode()) {
    console.log('Consent Choice Saving...');
    console.log('Consent Data: ', data)
  }

  Cookies.remove(CookieContentClarity)
  Cookies.set(CookieContentClarity, data.clarity.toString(), { expires: ExpirationDays })

  Cookies.remove(CookieContentGtag)
  Cookies.set(CookieContentGtag, Object.keys(data.gtagGrants).join(','), { expires: ExpirationDays })

  Cookies.remove(CookieContentFbPixel)
  Cookies.set(CookieContentFbPixel, data.facebook.toString(), { expires: ExpirationDays })
}

export function saveConsentChoice() {
  let confGroups = getModalConfig().modal.cookieGroups
  const state = get(modalState);
  const allowedGroups = state.allowedGroups

  const allowedGrants: Grants[] = []
  let allowedFacebook = false
  let allowedClarity = false

  Object.entries(confGroups).map(([group, groupData]) => {
    if (allowedGroups.includes(group) || isGroupNecessary(groupData)) {
      for (const vendor of groupData.vendors.keys) {
        if (vendor === FacebookVendor) {
          allowedFacebook = true
        }

        if (vendor === GoogleVendor) {
          for (const tag of groupData.grants) {
            if (!allowedGrants.includes(tag)) {
              allowedGrants.push(tag)
            }
          }
        }

        if (vendor === ClarityVendor) {
          allowedClarity = true
        }
      }
    }
  })


  const gtagGrants = {} as Record<string, "granted">
  allowedGrants.forEach((grant) => {
    gtagGrants[grant] = 'granted'
  })

  const data: StoredConsentData = {
    facebook: allowedFacebook,
    google: allowedGrants.length > 0,
    gtagGrants: gtagGrants,
    clarity: allowedClarity,
  }

  saveConsentToCookie(data)
  handleConsentChoice(data)
  closeCookieModal()
}

export function allowAllCookies() {
  if (isDebugMode()) {
    console.log('Allow All Cookies')
  }

  const data: StoredConsentData = {
    facebook: true,
    google: true,
    gtagGrants: {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted'
    },
    clarity: true,
  }

  saveConsentToCookie(data)
  handleConsentChoice(data)
  closeCookieModal()
}

