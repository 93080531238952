<script lang="ts">
  import {getModalConfig} from "src/lib/config.ts";
  import type {CookieGroup} from "src/lib/config.ts";
  import Switch from "./Switch.svelte";
  import {isGroupNecessary} from "$lib/cookie-data.ts";
  import {allowGroup, disallowGroup, modalState} from "$lib/store.ts";

  export let group: CookieGroup;

  const  config = getModalConfig()

  const color = config.modal?.style?.primaryColor ?? '#005080';

  function handleChange(data: CustomEvent) {
    if (data.detail.enabled) {
      allowGroup(group)
    } else {
      disallowGroup(group)
    }
  }

  let isNecessary = false;
  if (isGroupNecessary(group)) {
    isNecessary = true
  }

  let enabled = isNecessary;
  if ($modalState.allowedGroups?.includes(group?.id)) {
    enabled = true;
  }
</script>

<Switch enabled={enabled} readOnly={isNecessary} {color} on:change={handleChange} />
