import {CookieVendorKey, Grants} from "$lib/cookie-data.ts"

interface InitCookieModal {
  debug?: boolean
  rootId: string
  modal: ModalConfig

  pixelId?: string
  gtagId?: string
  extraGTagsIds?: string[]
  clarityId?: string
}

interface CookieDetails {
  id: string
  name: string
  description: string
  type: string
  expiration: string
}

interface CookieVendorConfig {
  id: string
  name: string
  url: string
  cookieCount: number
  cookieDetails: {
    [id: string]: CookieDetails
  }
}

interface CookieVendors {
  keys: CookieVendorKey[]
  config: {
    [id: string]: CookieVendorConfig
  }
}

interface CookieGroup {
  id: string
  title: string
  content: string
  grants: Grants[]
  cookiePatterns: string[]
  vendors: CookieVendors
}

interface CookieGroups {
  [id: string]: CookieGroup
}

type ButtonConfig = {
  text: string
  styles?: string
}

interface ModalConfig {
  title: string
  content: string
  about: string
  style: {
    primaryColor: string
    secondaryColor: string
  }
  cookieGroups: CookieGroups
}

let modalConfig: InitCookieModal

export function setModalConfig(config: InitCookieModal) {
  modalConfig = config
}

export function getModalConfig() {
  return modalConfig
}

export function isDebugMode() {
  return modalConfig.debug
}

export type {
  InitCookieModal,
  CookieDetails,
  CookieVendorConfig,
  CookieVendors,
  CookieGroup,
  CookieGroups,
  ButtonConfig,
  ModalConfig
}
