<script lang="ts">
  import {addClasses, toStyles} from "$lib/utils"
  import { createEventDispatcher } from 'svelte';
  import type {ButtonConfig} from "src/lib/config.ts"
  const dispatch = createEventDispatcher();

  export let buttonData: ButtonConfig
  export let variant: 'accept' | 'confirm' = 'confirm'
</script>

<button class={addClasses(
  variant === 'accept' && 'cc-accept-button cctw-bg-ccPrimary cctw-text-ccPrimary-text',
  variant === 'confirm' && 'cc-configure-button cctw-bg-ccSecondary cctw-text-textColor-secondary'
)}
        style={toStyles(buttonData.styles ?? "")}
        on:click={() => dispatch('click')}
>
  {buttonData.text}
</button>

<style>
  .cc-accept-button {
  opacity: unset;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }

  .cc-configure-button {
    opacity: unset;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-transform: uppercase
  }

  @media (max-width: 768px) {
    .cc-configure-button, .cc-accept-button {
      min-width: 220px;
    }
  }
</style>
