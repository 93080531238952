<script lang="ts">
  import type {CookieDetails} from "src/lib/config.ts";

  export let cookieDetails: {
    [id: string]: CookieDetails
  }

  const cookieDetailList = Object.values(cookieDetails)
</script>

{#each cookieDetailList as cookieDetail}
  <div class="cc-cookie-details cctw-bg-ccModalBg cctw-border cctw-border-ccBorder">
    <div class="cctw-font-bold">{cookieDetail.name}</div>
    <p>{cookieDetail.description}</p>
    <hr class="hr-color cctw-bg-ccBorder" />
    <div class="cctw-flex cctw-flex-row cctw-justify-between">
      <div>
        <span class="cctw-font-bold">
          Живот на бисквитката
        </span>
        <span style="opacity: 0.9">
          {cookieDetail.expiration}
        </span>
      </div>

      <div>
        <span class="cctw-font-bold">
          Тип
        </span>
        <span>
          {cookieDetail.type}
        </span>
      </div>
    </div>
  </div>
{/each}

<style>
  .cc-cookie-details {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .hr-color {
      border: none;
      height: 2px;
  }
</style>
