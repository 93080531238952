<script lang="ts">
  import type {CookieGroup} from "$lib/config.ts"
  import GroupSwitch from "../GroupSwitch.svelte";
  import {toStyles} from "$lib/utils.js";
  import VendorDetails from "src/components/CookieGroup/VendorDetails.svelte";

  export let group: CookieGroup
  let details = false
</script>

<div class="cc-group cctw-border-solid cctw-border-t-0 cctw-border-l-0 cctw-border-r-0 cctw-border-b-2 cctw-border-b-ccBorder">
  <div class="cc-header">
    <span class="title">{group.title}</span>
    <div style="min-width: 60px">
      <GroupSwitch group={group}/>
    </div>
  </div>
  <p class="description">
    {group.content}
    <a href="/#"
       class="cctw-underline cctw-block cctw-pointer"
       on:click|preventDefault={() => details = !details}>
      Повече информация
    </a>
  </p>
  <div class="description" style={toStyles({
        height: details ? 'auto' : '0',
        overflow: details ? 'visible' : 'hidden',
      })}>
    <VendorDetails vendors={group.vendors}/>
  </div>
</div>

<style>
    .cc-group {
        margin-bottom: 1rem;
    }

    .cc-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
    }

    .title {
        font-size: 1.125rem;
        font-weight: bold;
        text-align: left;
    }

    @media (min-width: 768px) {
        .title {
            font-size: 1.5rem;
        }
    }

    .description {
        text-align: justify;
        margin-bottom: 0.2rem;
    }
</style>
