import {getModalConfig, isDebugMode} from "src/lib/config.ts"
import {StoredConsentData} from "$lib/cookie-data.ts";

declare global {
  interface Window {
    customFbq: (action: string, data: string) => void
    dataLayer: any[]
    gtag: (action: string, data: string | Date, value?: { [key: string]: any }) => void
  }
}

export function handleConsentChoice(data: StoredConsentData) {
  if (isDebugMode()) {
    console.log('Stored Consent Data: ', data)
  }

  if (!data) {
    return
  }

  initializePixel(getModalConfig().pixelId ?? "", data.facebook)

  if (data.google) {
    initializeGTag(
      getModalConfig().gtagId ?? "",
      getModalConfig().extraGTagsIds ?? [],
      data.gtagGrants
    )
  }

  if (data.clarity) {
    initializeClarity(getModalConfig().clarityId ?? "")
  }
}

export function initializePixel(pixelId: string, enabled: boolean) {
  if (!pixelId) {
    if (isDebugMode()) {
      console.log('pixelId not configured')
    }

    return
  }

  if (!window.customFbq) {
    console.log('fbq not found')
    return
  }

  if (enabled) {
    window.customFbq('consent', 'grant');
    if (isDebugMode()) {
      console.log('fbq consent granted')
    }
  } else {
    window.customFbq('consent', 'revoke')
    if (isDebugMode()) {
      console.log('fbq consent revoked')
    }
  }

  window.customFbq('init', pixelId)
  window.customFbq('track', 'PageView')
}

export function initializeClarity(clarityId: string) {
  if (isDebugMode()) {
    console.log('Init Clarity: ', clarityId)
  }

  if (!clarityId) {
    return
  }

  // @ts-ignore
  (function(c: any,l: any,a: any,r: any,i: any,t: any,y: any){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", clarityId);
}

export function initializeGTag(gtagId: string, additionalGTags: string[], grants: Record<string, string> = {}): void {
  if (!gtagId) {
    if (isDebugMode()) {
      console.log('gtagId not configured')
    }

    return
  }

  if (!window.gtag) {
    console.log('gtag not found')
    return
  }

  if (isDebugMode()) {
    console.log('GTag Configure: ', gtagId)
    console.log('Additional Tags: ', additionalGTags);
  }

  // initial configured by loading script
  if (gtagId.toLowerCase().indexOf('g-') != -1) {
    window.gtag('js', new Date());
    window.gtag('config', gtagId);
  }
  for (const tag of additionalGTags) {
    window.gtag('config', tag);
  }

  if (isDebugMode()) {
    console.log("Gtag Grants:", grants)
  }

  if (!grants || Object.keys(grants).length < 1) {
    return
  }

  window.gtag('consent', 'update', grants);
}

export const FacebookCookiePatterns = [
  '_fbp', 'fr', 'datr', 'sb', 'c_user', 'xs', 'spin', 'wd', 'dpr', 'presence',
];

export const GoogleCookiePatterns = [
  "__gsas", "__eoi", "__gpi", "__gpi_optout", "__gads", "GED_PLAYLIST_ACTIVITY",
  "FPAU", "FPGCLDC", "_gcl_dc", "_gcl_au", "FPGCLAW", "FPGCLGB", "_gcl_gb",
  "gac_gb*", "_gcl_aw", "FPLC", "_ga", "gac*", "_gid", "gat\\[*", "__utma", "__utmb", "__utmc",
  "__utmt", "__utmz", "__utmv", "AMP_TOKEN", "FPID",
  // custom
  "_ga_*",
  //
  "ga*", "dc_gtm*", "_gaexp", "_gaexp_rc", "_opt_awcid", "_opt_awmid", "_opt_awgid", "_opt_awkid",
  "_opt_utmc", "_gcl_gf", "_gcl_ha",
];

export const ClarityCookiePatterns = [
  '_clck', '_clsk', '_cltk', 'CLID', 'ANONCHK', 'MUID',
]
