<script lang="ts">
  import type {ModalConfig} from "src/lib/config.ts"
  import GroupSwitch from "src/components/GroupSwitch.svelte";

  export let modalConfig: ModalConfig
</script>

<h4 class="cc-title">
  {modalConfig?.title ?? "Cookie Consent"}
</h4>
{#if !!modalConfig.content}
  <p class="cc-text-content">
    {@html modalConfig.content}
  </p>
{/if}
