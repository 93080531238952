<script lang="ts">
  import type {CookieVendors} from "$lib/config.ts"
  import CookieDetails from "src/components/CookieGroup/CookieDetails.svelte";

  export let vendors: CookieVendors

  const vendorConfigList = Object.entries(vendors.config)
  let openedVendors: string[] = []

</script>

{#each vendorConfigList as [key, vendorConfig]}
  <div class="vendor-wrapper cctw-border cctw-border-ccBorder cctw-bg-ccModalBg-hover cctw-cursor-pointer">
    <div class={"cctw-flex cctw-flex-row cctw-justify-between"}
        on:click|preventDefault={() => {
          if (openedVendors.includes(key)) {
            openedVendors = openedVendors.filter((vendor) => vendor !== key)
          } else {
            openedVendors = [...openedVendors, key]
          }
        }}
         role="button"
         tabindex="0"
         on:keypress={() => {}}
    >
      <div class="cctw-flex cctw-flex-row cctw-justify-start cctw-gap-2 cctw-items-center">
        <div>{vendorConfig.name}</div>
        <div class='vendor-count cctw-bg-ccModalBg'>{vendorConfig.cookieCount}</div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cctw-w-6 cctw-h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
      </div>
    </div>
    {#if openedVendors.includes(key)}
      <div style="padding-top: 1rem">
        <CookieDetails cookieDetails={vendorConfig.cookieDetails} />
      </div>
    {/if}
  </div>
{/each}


<style>
  .vendor-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      padding: 15px;
      margin-bottom: 10px;
  }

  .vendor-count {
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding: 5px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    .vendor-wrapper {
      padding: 5px;
    }
  }
</style>

